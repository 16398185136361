import useSanityClient from '@/clients/sanity/'

const preview = (
  query: string,
  params?: {
    id?: string
  },
) => {
  let inThrottle = false

  const reload = () => {
    inThrottle = true

    // eslint-disable-next-line no-console
    console.log('Page is refreshing...')
    window.location.reload()
  }

  useSanityClient.listen(query, params).subscribe(() => {
    if (inThrottle) return

    setTimeout(reload, 2000)
  })
}

export default preview
